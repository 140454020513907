.top-category{
    &.top-contact{
        .container{
            padding: 2.1em 0 35em;
        }
    }
}

.contact-content{
    .contact-form{
        margin-top: -33em;
        padding: 1em 1em;
        box-shadow: 0 5px 5px rgba($black, .14);
        margin-bottom: 2.1em;
        .main-title{
            margin-top: .7em;
        }
        input, textarea{
            border: 1px solid $grayl2;
        }
        .contact-info{
            padding: 2.1em 0;
            text-align: center;
            p:not(:last-child){
                margin-bottom: .7em;
            }
        }
        @include mq('md'){
            margin-top: -30em;
            max-width: 1200px;
            form{
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            input, textarea{
                max-width: 500px;
            }
            .contact-info{
                max-width: 900px;
                margin: auto;
                display: flex;
                justify-content: space-around;
            }
        }
    }
}