.tabs-nav{
    display: flex;
    flex-direction: column;
    text-align: center;
    a{
        padding: 2.1em 0;
        font-size: 18px;
        border-bottom: 1px solid $grayl2;
        color: $grayl2;
        width: 100%;
        cursor: pointer;
        &.active{
            font-weight: 700;
            &.clasico,
            &.talco,
            &.spray{
                color: $blue;
                border-color: $blue;
            }
            &.derma{
                color: $derma;
                border-color: $derma;
            }
            &.control{
                color: $bluebox;
                border-color: $bluebox;
            }

        }
    }
    @include mq('md'){
        flex-direction: row;
    }
}