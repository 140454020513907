.subscribe{
    background: $grayl;
    padding: 2.8em 0 4.2em;
    &-form{
        margin-top: 2.8em;
        max-width: 770px;
        @include mq('md'){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 4.8em;
            input{
                width: 49%;
                &.main-btn{
                    margin: 0 0 1.4em;
                    width: 28%;
                }
                &.long-input{
                    width: 70%;
                }
            }
        }
    }
}