
// COLORS
$blue: #0060A4;
$bluebox: #25609F;
$bluebtn: #0081C7;
$blued: #0D1434;
$derma: #7543C2;
$white: #ffffff;
$grayl: #F4F4F4;
$grayl2: #C8C8C8;
$gray:  #818181;
$black: #26272B; 
