form{
    input,
    textarea{
        color: $blue;
        border: none;
        background: $white;
        padding: 1em;
        width: 100%;
        margin-bottom: 1.4em;
        font-size: 18px;
        font-family: 'Arimo', sans-serif;
        @include placeholder {
            color: $blue;
        }
        &.main-btn{
            box-shadow: none;
            cursor: pointer;
            padding: 1em 2.8em;
            font-size: 18px;
            &:hover{
                border: 1px solid $bluebtn;
            }
        }
    }
}