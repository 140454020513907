.top-category{
    background: rgb(37,95,173);
    background: -moz-linear-gradient(180deg, rgba(6,24,115,1) 0%, rgba(37,95,173,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(6,24,115,1) 0%, rgba(37,95,173,1) 100%);
    background: linear-gradient(180deg, rgba(6,24,115,1) 0%, rgba(37,95,173,1) 100%);
    color: $white;
    text-align: center;
    .container{
        padding: 2.1em 0;
    }
    p{
        text-align: left;
        font-size: 18px;
    }
    @include mq('md'){
        .container{
            padding: 4.9em 0;
            width: 84%;
            max-width: 1400px;
        }
        h1{
            text-align: left;
            width: 100%;
            margin: 1.7em 0 .7em;
        }
        p{
            font-size: 21px;
        }
    }
}