*, *:before, *:after{
    box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: none;
}

small{
    font-size: 70%;
}

html {
    scroll-behavior: smooth;
  }

footer, header, nav, section, main{
    display: block;
}

body{
    line-height: 1;
    font-family: 'Arimo', sans-serif;
    color: $blue;
    letter-spacing: 1px;
}

ol, ul{
    list-style: none;
}

blockquote, q{
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after{
    content: '';
    content: none;
}

table{
    border-collapse: collapse;
    border-spacing: 0;
}

input{
    -webkit-appearance: none;
    border-radius: 0;
}
a{
    color: inherit;
    text-decoration: none;
}
img{
    max-width: 100%;
    height: auto;
}

.container{
    width: 87%;
    max-width: 1500px;
    margin: auto;
}

p{
    letter-spacing: 1.3px;
    line-height: 1.3;
}

.display{
    &-desktop{
        display: none;
    }
    &-desktop-tablet{
        display: none;
    }
    @include mq('sm'){
        &-desktop{
            display: block;
        }
        &-desktop-tablet{
            display: block;
        }
        &-mobile{
            display: none;
        }
    }
    @media (orientation: landscape){
        &-desktop{
            display: block;
        }
        &-desktop-tablet{
            display: block;
        }
        &-mobile{
            display: none;
        }
     }
}

.gray-bg{
    background: #f8f8f8;
}


@media (max-width: 576px){.mobile-image-100 {width: 100%;}}
@media(max-width: 991px){.about-block .about-block-columns{text-align: center;}.about-block .about-text{text-align: left;margin: auto;}}
@media (min-width: 576px){.display-desktop{display:block}.display-desktop-tablet{display:block}.display-mobile{display:none}}