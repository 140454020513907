.main-btn{
    border: 1px solid $blue;
    display: inline-block;
    padding: .7em 2.8em;
    font-size: 17px;
    margin: 1.4em auto;
    text-align: center;
    font-weight: 700;
    &:hover{
        background: $bluebtn;
        color: $white;
    }
    &-blue{
        background: $blue;
        color: $white;
        &:hover{
            background: transparent;
            border: 1px solid $bluebtn;
            color: $blue;
        }
    }
}

.arrow-down{
    width: 39px;
    height: 39px;
    font-size: 0;
    background: url('../images/icons/slider-arrow-right.svg') no-repeat;
    background-size: contain;
    display: inline-block;
    transform: rotate(90deg) translateX(-50%);
    transform-origin: left;
    position: absolute;
    left: 50%;
    bottom: 10px;
    // bottom: -5%;
    @include mq('md'){
        width: 49px;
        height: 49px;
        bottom: 4%;
    }
}