.pqr{
    .intro-text{
        text-align: center;
        margin: 1.4em auto 2.8em;
        @extend .container;
    }
    &-container.container{
        margin-bottom: 10em;
        width: 91%;
        .flickity-button{
            top: inherit;
            bottom: -5em;
            transform: translateX(-50%);
            left: 35%;
            &.next{
                left: 63%;
            }
        }
        @include mq('md'){
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: flex-start;
            max-width: 1200px;
            margin-bottom: 5em;
        }
    }
    &-item{
        width: 100%;
        padding: 0 .4em 1em;
        &-container{
            background: $white;
            box-shadow: 0 5px 5px rgba($black, .14);
            padding: 2.1em 1.7em;
            h4{
                font-weight: 700;
                margin: 1em 0;
                font-size: 25px;
            }
        }
    }
    @include mq('md'){
        .intro-text{
            font-size: 21px;
            max-width: 650px;
        }
        &-item{
            width: 32%;
            max-width: 370px;
            padding: 0;
            margin-bottom: 2.8em;
            &:not(:nth-child(3n+3)){
                margin-right: 2.3%;
            }
            // &:nth-child(3n+2){
            //     margin: 0 3% 2.8em;
            // }
            &-container{
                height: 100%;
            }
        }
    }
    @include mq('lg'){
        &-item{
            &:not(:nth-child(3n+3)){
                margin-right: 3.2%;
            }
        }
    }
}