.header{
    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &.fixed{
        position: absolute;
        left:0;
        width:100%;
        top:-10px;
        z-index:1001;
        background-color: #FFFFFF;
        padding: 1em 7%;
    }
    position: sticky;
    top: 0;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 7%;
    z-index:1001;
    .logo{
        width: 49%;
    }
    @include mq('md'){
        padding: 1em 0;
       /*  max-width: 1500px; */
        width: 100%;
        margin: auto;
        .logo{
            width: auto;
        }
    }
}