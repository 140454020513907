.politics{
    .main-title{
        border-bottom: 1px solid #0060a494;
        margin: 0;
        padding-bottom: 15px;
        width: 100%;
        margin-bottom: 30px;
    }
    .text{
        line-height: 21px;        
        @include mq('md'){
            font-size: 20px;
            line-height: 28px;
        }
        .strong{
            font-weight: bolder;
        }
    }
   
}