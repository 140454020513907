.slider-block{
    &:not(.hero-banner-container){
        @extend .container;
    }
    @include mq('md'){
        &.full-width{
            width: 115%;
            max-width: 1700px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .flickity-button{
        width: 49px;
        height: 49px;
        background: url('../images/icons/slider-arrow-right.svg') no-repeat;
        background-size: contain;
        top: 41%;
        box-shadow: none;
        @media (max-width: $screen-sm ) {
            width: 30px;
            height: 30px;
        }
        svg{
            display: none;
        }
        &.next{
            right: 0;
            @media (max-width: $screen-sm ){
                right: -20px;
            }
        }
        &.previous{
            left: 0;
            transform: translateY(-50%);
            background: url('../images/icons/slider-arrow-left.svg') no-repeat;
            background-size: contain;
            @media (max-width: $screen-sm ){
                left: -20px;
            }
        }
        &:disabled{
            opacity: 0;
        }
    }
    .flickity-page-dots{
        bottom: 50px;
        .dot{
            border: 1px solid $blue;
            width: 15px;
            height: 15px;
            border-radius: 35%;
            background: transparent;
            opacity: 1;
            margin: 0 5px;
            &.is-selected{
                background: $blue;
            }
        }
        @include mq('md'){
            bottom: 13%;
            .dot {
                width: 17px;
                height: 17px;
            }
        }
    }
    
    &.hero-banner{
        &-container{
            .hero-banner-item{
                width: 100%;
                img {
                    width: 100%;
                }
                .main-btn{
                    position: absolute;
                    bottom: 4.6em;
                    left: 50%;
                    transform: translateX(-50%);
                    padding: 0.5em 2.3em;
                    font-size: 15px;
                    font-weight: 700;
                    @include mq('sm'){
                        left: 21%;
                        bottom: inherit;
                        padding: 0.7em 2.8em;
                        font-size: 17px;
                        top: 65%;
                        transform: translate(-50%, -50%);
                        font-size: 20px;
                    }
                    @media (orientation: landscape){
                        bottom: 20%;
                        left: 21%;
                        @include mq('sm'){
                            bottom: inherit;
                        }
                    }
                }
            }
            .flickity-button{
                display: none;
                top: 50%;
                transition: all .5s;
                &.next{
                    right: 4%;
                }
                &.previous{
                    left: 4%;
                }
                @include mq('md'){
                    display: block;
                }
            }
            &.whiteArrows{
                .flickity-button{
                    filter: brightness(50);
                }
                .flickity-page-dots{
                    transition: all .5s;
                    .dot{
                        border-color: $white;
                        &.is-selected{
                            background: $white;
                        }
                    }
                }
                + .arrow-down{
                    filter: brightness(50);
                }
                .main-btn{
                    border-color: $white;
                    color: $white;
                }
            }
            &.purpleArrows{
                .flickity-button{
                    &.next{
                        background: url('../images/icons/slider-arrow-right-purple.svg') no-repeat;
                        background-size: contain;
                    }
                    &.previous{
                        background: url('../images/icons/slider-arrow-left-purple.svg') no-repeat;
                        background-size: contain;
                    }
                }
                .flickity-page-dots{
                    transition: all .5s;
                    .dot{
                        border-color: $derma;
                        &.is-selected{
                            background: $derma;
                        }
                    }
                }
                + .arrow-down{
                    background: url('../images/icons/slider-arrow-right-purple.svg') no-repeat;
                    background-size: contain;
                }
                .main-btn{
                    border-color: $derma;
                    color: $derma;
                    &:hover{
                        background: $derma;
                        color: $white;
                    }
                }
            }
        }
    }
    &.products-slider{
        margin-bottom: 4.2em;
    }
}

.hero-banner{
    position: relative;
}

.main-sliders-container .products-slider{
    width: 87%;
    &:not(.clasico){
        display: none;
    }
}