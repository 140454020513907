.main-title{
    font-weight: 700;
    font-size: 28px;
    width: 91%;
    margin: 1.4em auto;
    text-align: center;
    &.lines{
        position: relative;
        &::before,
        &::after{
            content: '';
            height: 2px;
            width: 14%;
            background: $blue;
            left: -14%;
            top: 50%;
            transform: translatey(-50%);
            position: absolute;
        }
        &::after{
            left: inherit;
            right: -13%;
        }
    }
    &.mb-1{
        margin-bottom: 1em;
    }
    @include mq('md'){
        font-size: 40px;
        margin: 2.1em auto;
        &.title-left{
            width: 100%;
            text-align: left;
        }
        &.lines{
            width: 100%;
            &::before{
                width: 30%;
                left: 0;
            }
            &::after{
                width: 30%;
                right: 0;
            }
            &-short{
                &::before,
                &::after{
                    width: 7%;
                }
            }
        }
    }
    @include mq('lg'){
        font-size: 40px;
        margin: 2.1em auto;
        &.title-left{
            width: 100%;
            text-align: left;
        }
        &.lines{
            width: 100%;
            &::before{
                width: 33%;
                left: 0;
            }
            &::after{
                width: 33%;
                right: 0;
            }
            &-short{
                &::before,
                &::after{
                    width: 16%;
                }
            }
        }
    }
}