.boxes{
    &-block{
        padding: 2.8em 0 4.9em;
    }
    &-item{
        padding: 2.1em 1.7em 4.9em;
        box-shadow: 0 5px 5px rgba($black, .14);
        position: relative;
        background: $white;
        color: $black;
        &:not(:last-child){
            margin-bottom: 2.8em;
            &::after{
                content: '';
                position: absolute;
                left: 50%;
                bottom: 10%;
                transform: translateX(-50%) rotate(45deg);
                width: 21px;
                height: 21px;
                border-bottom: 1px solid $black;
                border-right: 1px solid $black;
            }
        }
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.4rem;
            color: $blue;
            background-color: $grayl;
            width: 49px;
            height: 49px;
            border-radius: 50%;
        }
        h3{
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 700;
            margin: 1em 0;
        }
        p{
            font-size: 18px;
            min-height: 49px;
        }
        &.item-blue{
            background: $bluebtn;
            color: $white;
            &::after{
                border-color: $white;
            }
        }
        &.item-darkblue{
            background: $blue;
            color: $white;
        }
        &.item-gray{
            background: $gray;
            color: $white;
            &::after{
                border-color: $white;
            }
        }
        &.item-black{
            background: $black;
            color: $white;
        }
    }
    @include mq('md'){
        &-block{
            padding: 2.8em 0 9.1em;
        }
        &-container{
            display: flex;
            align-items: stretch;
            justify-content: center;
            width: 84%;
            max-width: 1400px;
        }
        &-item{
            width: 33.3%;
            padding: 2.8em 5.6em 6.3em 3.5em;
            &:not(:last-child){
                margin-bottom: 0;
                &::after{
                    left: inherit;
                    right: 10%;
                    transform: translateY(-50%) rotate(-45deg);
                    top: 50%;
                    width: 28px;
                    height: 28px;
                }
            }
            h3{
                font-size: 28px;
                margin: 1.6em auto;
            }
            p{
                font-size: 21px;
            }
        }
    }
}