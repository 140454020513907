.portfolio{
    &-container{
        width: 100%;
        @include mq('md'){
            width: 91%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 7em;
            align-items: stretch;
            + h3{
                display: none;
            }
        }
    }
    &-img{
        position: relative;
        img{
            width: 100%;
            @media (min-width: 541px){
                width: 50%;
            }
             @include mq('md'){
                 width: 100%;
             }
        }
    }
    &-item{
        text-align: center;
        position: relative;
        // padding-top: 2.1em;
        margin-bottom: 4.9em;
        .new-img{
            position: absolute;
            left: 50%;
            top: 7%;
            transform: translateX(-50%);
            z-index: 1;
            max-width: 180px;
        }
        h3{
            font-weight: 700;
            position: relative;
            position: absolute;
            left: 50%;
            width: 91%;
            bottom: 7%;
            transform: translateX(-50%);
            font-size: 21px;
            text-transform: uppercase;
        }
        p{
            display: none;
        }
        .main-btn{
            position: relative;
        }
        &.clasico{

        }
        &.derma{
            h3{
                color: $derma;
            }
            .main-btn{
                color: $derma;
                border-color: $derma;
            }
        }
        &.control{
            h3{
                color: $bluebox;
            }
            .main-btn{
                color: $bluebox;
                border-color: $bluebox;
            }
        }
        &.pies{
           
        }
        @include mq('md'){
            width: 23%;
            box-shadow: 0 5px 5px rgba($black, .14);
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .new-img{
                width: 180px;
                top: 1.4%;
            }
            p{
                display: block;
                text-align: left;
                padding: 1em 1.4em 0;
                min-height: 114px;
            }
            .main-btn{
                top: inherit;
            }
            &.control,
            &.derma{
                .main-btn{
                    color: $blue;
                    border-color: $blue;
                    &:hover{
                        color: $white;
                    }
                }
            }
            &.pies{
                margin-bottom: 0;
            }
            &.itemActive{
                .portfolio-text{
                    background: $blue;
                    color: $white;
                    position: relative;
                    &::after{
                        content: '';
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        display: inline-block;
                        border-top: 28px solid $blue;
                        border-left: 27px solid transparent;
                        border-right: 27px solid transparent;
                        bottom: -27px;
                    }
                    .main-btn{
                        border-color: $bluebtn;
                        background: $bluebtn;
                        color: $white;
                    }
                }
            }
        }
    }
    &-info{
        &:after {
            content: 'flickity';
            display: none; /* hide :after */
            @include mq('md'){
                content: '';
            }
        }
        &-item{
            width: 100%;
        }
        iframe{
            height: 200px;
            margin-bottom: 1.4em;
        }
        img{
            height: 200px;
            width: 100%;
            object-fit: cover;
        }
        &-text{
            text-align: center;
            h3{
                width: 84%;
            }
            h5{
                @extend .main-title;
                font-size: 23px;
            }
            p{
                text-align: left;
                margin: 2.4em auto 1.4em;
                strong{
                    font-weight: 700;
                }
            }
        }
        &-item{
            &.derma{
                .portfolio-info-text{
                    color: $derma;
                }
                .main-btn{
                    border-color: $derma;
                }
            }
        }
        @include mq('md'){
            + .main-title{
                text-align: left;
                width: 91%;
                max-width: 1500px;
            }
            &-item{
                display: flex;
                &:not(.clasico){
                    display: none;
                }
            }
            iframe, img{
                height: 470px;
                min-width: 730px;
            }
            // img{
            //     width: 54.8%;;
            // }
            &-text{
                width: 80%;
                padding-left: 3%;
                text-align: left;
                h3{
                    margin: .5em 0 1.4em 0;
                    text-align: left;
                    width: 100%;
                }
                h5{
                    text-align: left;
                    margin-left: 0;
                    margin-bottom: 0;
                    font-size: 25px;
                }
                p{
                    margin-top: 1.4em;
                    font-size: 18px;
                }
            }
        }
    }
}

.products-slider{
    &-item{
        width: 100%;
        text-align: center;
        img.space{
            margin-bottom: 37.5%;
            @include mq('md'){
                margin-bottom: 37.5%;
            }
        }
        h4{
            font-weight: 700;
            margin-bottom: .4em;
            font-size: 20px;
            min-height: 40px;
        }
        p{
            font-size: 20px;
        }
        @include mq('md'){
            width: 24%;
            padding: 0 1.7%;
            .new-img{
                width: 180px;
                top: 1.4%;
            }
        }
    }
    &.sliderRemoved{
        display: flex;
        justify-content: space-evenly;
        img.space{
            margin-bottom: 37.5%;
            @include mq('md'){
                margin-bottom: 37.5%;
            }
        }
    }
}