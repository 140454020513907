.breadcrumbs{
    display: none;
    li{
        margin: 0 .3em;
        &:first-child{
            margin-left: 0;
        }
        &:nth-last-child(-n+2){
            font-weight: 700;
        }
    }
    @include mq('md'){
        display: flex;
    }
}

.top-block{
    @include mq('md'){
        .container{
            padding: 4.9em 0 2.8em;
            width: 91%;
            max-width: 1500px;
        }
    }
}