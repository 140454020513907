.header {
    .toogle-nav {
        span {
            background: $blue;
            width: 26px;
            height: 4px;
            display: block;
            transition: all .5s;

            &:not(:last-child) {
                margin-bottom: .35em;
            }
        }

        @include mq('md') {
            display: none;
        }
    }

    .nav-search {
        opacity: 0;
        z-index: -1;
        position: absolute;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 100%;
        background: $white;
        color: $gray;
        display: flex;
        flex-direction: column;
        text-align: center;
        text-transform: uppercase;
        font-size: 18px;
        transition: all .5s;
        pointer-events: none;

        @media (orientation: landscape){
            height: 100vh;
            overflow-y: scroll;
            font-size: 15.5px;
         }

        nav {
            overflow: auto;
            li {
                padding: 1.7em 0;
                border-bottom: 1px solid $grayl2;
                @media (orientation: landscape){
                    padding: 1.5em 0;
                }
            }
        }

        .search-box {
            order: -1;
            padding: 1.7em 0;
            border-bottom: 1px solid $blue;

            input {
                border: none;
                outline: none;
                padding: .7em;
                width: auto;
                margin-bottom: inherit;

                @include placeholder {
                    color: $blue;
                    text-transform: uppercase;
                }
            }

            img {
                width: 24px;
            }
        }

        nav {
            li {
                position: relative;
                a {
                    &:hover, &.active {
                        color: $blue;
                        /* font-weight: bolder; */
                        &::before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            background: transparent;
                            border-bottom: 1px solid $blue;
                            width: 100%;
                            height: 100%;
                            z-index: 0;
                        }
                    }
                }
            }
        }

        @include mq('md') {
            opacity: 1;
            z-index: 1;
            position: relative;
            flex-direction: row;
            text-transform: inherit;
            background: $white;
            color: $blue;
            height: auto;
            width: auto;
            pointer-events: inherit;
            overflow-y: inherit;

            nav {
                overflow: inherit;
                ul {
                    display: flex;
                }

                li {
                    border: 0;
                    padding: 0;

                    a {
                        margin: 0 1.4em;

                        &.active,
                        &:hover {
                            position: relative;
                            color: $white;

                            &::before {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                background: $blue;
                                width: 140%;
                                height: 160%;
                                z-index: -1;
                            }
                        }
                    }
                }
            }

            .search-box {
                order: 1;
                padding: 0;
                border: none;
                margin-left: 1.4em;

                .search-input {
                    opacity: 0;
                    z-index: -1;
                    background: $blue;
                    width: 100%;
                    padding: .4em .7em;
                    position: absolute;
                    right: -.3em;
                    top: 50%;
                    transform: translateY(-50%);
                    text-transform: inherit;
                    color: $white;
                    transition: all .3s;

                    &::-webkit-input-placeholder {
                        color: $white;
                        text-transform: inherit;
                    }

                    &:-ms-input-placeholder {
                        color: $white;
                        text-transform: inherit;
                    }

                    &::placeholder {
                        text-transform: inherit;
                        color: $white;
                    }
                }

                img {
                    cursor: pointer;
                }

                &.searchOpen {
                    .search-input {
                        opacity: 1;
                        z-index: 1;
                    }

                    img {
                        position: relative;
                        z-index: 2;
                        filter: brightness(5);
                    }
                }
            }
        }
    }

    &.openNav {

        /* position: relative; */
        .toogle-nav {
            span {
                &:nth-child(1) {
                    transform: rotate(45deg);
                    margin-bottom: -.4em;
                }

                &:nth-child(2) {
                    opacity: 0;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                    margin-top: -.45em;
                }
            }
        }

        .nav-search {
            opacity: 1;
            z-index: 1;
            pointer-events: inherit;
        }
    }
    
}