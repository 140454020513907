.stores {
    &-content {
        .main-title {
            margin-top: 0;
            padding: 1.4em 1em 0;
        }
    }

    &-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &-item {
        background: $white;
        border-radius: 10px;
        box-shadow: 0 5px 5px rgba($black, .14);
        padding: .7em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2.1em;
        text-align: center;
        width: 100%;
        max-width: 225px;

        @media (min-width: 370px) {
            margin-left: .2em;
            margin-right: .2em;
            width: 48%;
        }

        .main-btn {
            padding: .5em;
            margin: 0;
            font-size: 16px;
        }

        img {
            height: 70px;
            max-width: 120px;
            object-fit: contain;
            display: block;
            margin: 0 auto 0.5em;
        }
    }

    @include mq('sm') {
        &-item {
            img {
                max-width: 140px;
            }
        }
    }

    @include mq('md') {
        &-grid {
            justify-content: flex-start;
            padding-bottom: 5.6em;
        }

        &-item {
            margin: 0 2.1% 3.5em;
        }
    }

    @include mq('xlg') {
        &-grid {
            justify-content: flex-start;
        }

        &-item {
            margin: 0 .56% 3.5em;
            padding: 1em;

            .main-btn {
                padding: .5em 1em;
                margin: 0;
                font-size: 17px;
            }

            img {
                margin-top: .4em;
                width: 84%;
                object-fit: contain;
            }
        }
    }
}


/* MODAL */

.bg-modal-info {
    background-color: rgba($black, .24);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1001;

    .modal-info {
        background-color: $white;
        padding: 4rem 2rem 4rem;
        position: relative;
        max-width: 500px;
        width: 90%;

        .modal-close {
            cursor: pointer;
            border: none;
            color: $blue;
            text-align: right;
            background-color: transparent;
            position: absolute;
            right: 1.5rem;
            top: 1.5rem;
            font-size: 25px;
        }

        .modal-text {
            text-align: center;

            .title {
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 1rem;
                word-break: break-word;
                hyphens: auto;

            }

            .text {
                font-size: 16px;
            }


        }

        .modal-actions {
            text-align: center;
            margin-top: 1rem;

            .main-btn {
                cursor: pointer;
                background-color: $white;
                color: $blue;
                min-width: 180px;
                margin: .4rem 0;

                &:nth-child(1) {
                    background-color: $blue;
                    color: $white;
                }
            }
        }

        @include mq('sm') {
            width: 80%;

            .modal-text {
                .title {
                    font-size: 22px;
                }

                .text {
                    font-size: 18px;
                }
            }

        }

        @include mq('md') {
            .modal-text {
                .title {
                    font-size: 30px;
                }
            }

        }
    }

    &.active {
        display: flex;
    }
}