.product{
    &-info{
        display: flex;
        flex-direction: column;
    }
    &-img{
        order: -1;
        text-align: center;
        &.vertical-img{
            @include mq('md'){
                margin-top: 25%;
            }
            @include mq('lg'){
                margin-top: 15%;
            }
        }
        img{
            width: 84%;
        }
    }
    &-text{
        text-align: center;
        .product{
            &-subtitle{
                font-size: 21px;
                font-weight: 600;
                width: 91%;
                margin: 1.4em auto;
            }
            // &-title{
            //     font-size: 28px;
            //     font-weight: 700;
            //     padding: 0 5%;
            // }
        }
        p{
            text-align: left;
            line-height: 1.5;
            span{
                font-weight: 700;
                display: block;
                margin-top: 1em;
                padding-right: 3.5em;
            }
        }
    }
    &-actions{
        .main-btn{
            display: none;
        }
        &-group{
            text-align: center;
            margin: 2.8em 0;
            > a{
                display: inline-block;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 19px;
                position: relative;
                margin-bottom: 2.1em;
                &::before{
                    content: '';
                    display: inline-block;
                    width: 21px;
                    height: 21px;
                    vertical-align: middle;
                    background: url('../images/icons/double-arrow.svg') no-repeat;
                    background-size: contain;
                    transform: scale(-1);
                    margin-right: .7em;
                }
            }
            .shares{
                // display: flex;
                display: none;
                align-items: center;
                justify-content: center;
                p{
                    font-weight: normal;
                    text-transform: uppercase;
                    margin-right: 2.4em;
                }
                a{
                    font-size: 0;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                }
                &-facebook{
                    background: url('../images/icons/facebook-share.svg') no-repeat;
                    background-size: contain;
                }
                &-youtube{
                    background: url('../images/icons/youtube-share.svg') no-repeat;
                    background-size: contain;
                    background-position: center;
                    margin-right: 7%;
                }
            }
        }
    }
    @include mq('md'){
        &-info{
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        &-img{
            order: 0;
            width: 52%;
            img{
                width: 77%;
            }
        }
        &-text{
            text-align: left;
            width: 44%;
            .main-title{
                margin: .7em 0;
                width: 84%;
            }
            .product-subtitle{
                width: 100%;
                text-align: left;
                font-size: 28px;
                margin: 0 0 3.5em;
            }
            p{
                font-size: 18px;
            }
            .main-btn{
                display: none;
            }
        }
        &-actions{
            width: 70%;
            text-align: center;
            .main-btn{
                display: inline-block;
                margin-top: 0;
            }
            &-group{
                display: flex;
                align-items: center;
                justify-content: space-between;
                > a{
                    margin-bottom: 0;
                    font-size: 21px;
                    &::before{
                        width: 28px;
                        height: 28px;
                        margin-right: 1.7em;
                    }
                    
                }
                .shares{
                    font-size: 20px;
                    a{
                        width: 30px;
                        height: 30px;
                    }
                    &-youtube{
                        margin-right: 35px;
                    }
                }
            }
        }
    }
}