footer{
    background: rgb(37,95,173);
    background: -moz-linear-gradient(180deg, rgba(6,24,115,1) 0%, rgba(37,95,173,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(6,24,115,1) 0%, rgba(37,95,173,1) 100%);
    background: linear-gradient(180deg, rgba(6,24,115,1) 0%, rgba(37,95,173,1) 100%);
    color: $white;
    font-size: 14px;
    .top.content{
        width: 84%;
        max-width: 1500px;
        margin: auto;
        padding: 2.8em 0;
        @include mq('md'){
            padding: 4.9em 0 2.8em;
            display: flex;
            justify-content: space-between;
        }
    }
    .simple-list{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2.8em;
        li{
            width: 49%;
            margin-bottom: 2.1em;
            &::before{
                content: '';
                display: inline-block;
                height: 10px;
                width: 10px;
                border-bottom: 1px solid $white;
                border-left: 1px solid $white;
                transform: rotate(-135deg);
                margin-right: 1em;
            }
        }
    }
    .column{
        display: flex;
        flex-direction: column;
        margin-bottom: 2.8em;
        img{
            max-width: 210px;
            width: 70%;
            margin: 0 auto 3.5em;
        }
        .simple-link{
            margin-bottom: 1.4em;
        }
        @include mq('md'){
            img{
                margin: 0 0 3.5em 0;
            }
        }
    }
    .social-networks{
        .single-text{
            margin-bottom: 2.1em;
        }
        .social-list{
            display: flex;
            justify-content: center;
            .simple-link{
                margin: 0 .5em;
            }
        }
    }
    .bottom{
        text-align: center;
        padding: 1.4em 2.1em;
        background: $blued;
        @include mq('md'){
            padding: 2.8em 2.1em;
        }
    }
}