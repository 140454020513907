.about{
    &-block{
        &-columns{
            @include mq('md'){
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                max-width: 1200px;
                margin: auto;
            }
        }
    }
    &-text{
        line-height: 21px;
        max-width: 530px;
        @include mq('md'){
            font-size: 20px;
            line-height: 28px;
        }
    }
    &-block{
        margin-bottom: 3.5em;
        @include mq('md'){
            margin-bottom: 9.1em;
        }
    }
}