.about{
    &-img{
        background: #f5f5f5;
        padding: 1em;
        box-shadow: 0 5px 5px rgba($black, .14);
        margin-bottom: 3.5em;
        display: inline-block;
        height: 320px;
        img {
            height: 100%;
        }
    }
    
    @include mq('md'){
        &-img{
            height: auto;
            min-height: 530px;
            max-width: 560px;
            width: 37%;
            margin-bottom: 0;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    @include mq('lg'){
        &-img{
            height: auto;
            min-height: 530px;
            max-width: 560px;
            width: 48%;
            margin-bottom: 0;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}