.category{
    &-content{
        @include mq('md'){
            width: 84%;
            max-width: 1400px;
            margin: auto;
        }
    }
    &-hero{
        &:not(&.active){
            display: none;
        }
        &-title{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 2.1em auto 2.8em;
            img{
                width: 14%;
                margin-right: 1em;
            }
            h2{
                width: auto;
                display: inline-block;
                margin: 0;
            }
        }
        p{
            text-align: center;
            width: 70%;
            margin: 2.1em auto;
            &.negrita{
                font-weight: bolder;
            }
        }
        @include mq('md'){
            &-title{
                justify-content: flex-start;
                margin: 3.5em auto;
                img{
                    width: auto;
                }
            }
            .display-desktop{
                width: 100%;
            }
            p{
                width: 91%;
                font-size: 21px;
                margin: 2.8em auto;
            }
        }
        &.derma{
            .category-hero-title, p{
                color: $derma;
            }
        }
    }
    &-products{
        &:not(&.active){
            display: none;
        }
        &-grid{
            width: 91%;
            margin: auto;
        }
        &-item{
            border: 1px solid #E4E4E4;
            position: relative;
            margin-bottom: 3.5em;
            box-shadow: 0 5px 5px rgba($black, .14);
            .new-img{
                position: absolute;
                right: 2%;
                top: 6%;
                width: 49%;
            }
            .item-content{
                border-top: 1px solid #E4E4E4;
                padding: 1.4em .8em;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    right: 2%;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 21px;
                    height: 21px;
                    background: url('../images/icons/double-arrow.svg') no-repeat;
                    background-size: contain;
                }
                &-title{
                    font-weight: 700;
                    margin-bottom: .3em;
                }
            }
        }
        &.derma{
            .main-title{
                color: $derma;
            }
            .category-products-item{
                .item-content{
                    color: $derma;
                    &::after{
                        background: url('../images/icons/double-arrow-derma.svg') no-repeat;
                        background-size: contain;
                    }
                }
            }
        }
        @include mq('md'){
            &-grid{
                width: auto;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
            &-item{
                width: 47%;
                .new-img{
                    width: 200px;
                }
                .item-image{
                    text-align: center;
                    img{
                        width: 77%;
                    }
                }
                .item-content{
                    padding: 1.7em 2.8em;
                    font-size: 21px;
                    &::after{
                        width: 28px;
                        height: 28px;
                        right: 7%;
                    }
                }
            }
        }
    }
}